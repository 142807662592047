import React from "react";

import {
	FooterContainer,
	FooterWrap,
	SocialLogo,
	FooterLink,
	FooterLinkItems,
	FooterLinkTitle,
	FooterLinksWrapper,
	SocialMediaWrap,
} from "./FooterElements";

import appstore from "../../images/apple_blue.svg";
import playstore from "../../images/playstore_blue.svg";

const Footer = () => {
	return (
		<>
			<FooterContainer>
				<FooterWrap>
					<SocialMediaWrap>
						<SocialLogo>
							<a
								href="https://apps.apple.com/nl/app/positieve-gezondheid/id1584255754?l=en"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={appstore} alt="" />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=io.shardly.positievegezondheid"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={playstore} alt="" />
							</a>
						</SocialLogo>
						<FooterLinksWrapper>
							<FooterLinkItems>
								<FooterLink to="/legal/privacy/">
									Algemene Voorwaarden
								</FooterLink>
								<FooterLink
									to="mailto:support@positievegezondheid.app"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FooterLink to="/updates">
										Updates
									</FooterLink>
									Support
								</FooterLink>
							</FooterLinkItems>
						</FooterLinksWrapper>
					</SocialMediaWrap>
					<FooterLinkTitle>
						Een product van Shardly &copy;
						{new Date().getFullYear()}
					</FooterLinkTitle>
				</FooterWrap>
			</FooterContainer>
		</>
	);
};

export default Footer;
