import styled from "styled-components";

export const Nav = styled.nav`
	background: #ffffff;
	height: 80px;
	display: flex;
	justify-content: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 999;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	max-width: 1024px;
`;

export const NavLogo = styled.a`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
`;

export const NavIcon = styled.img`
	height: 60px;
`;

export const NavItem = styled.li`
	height: 80px;
	list-style-type: none;
`;

export const NavLinks = styled.button`
    color: #ffffff;
    background: #3837FF;
    display: flex;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    padding: 0.5rem 1rem;
    align-items: center;
    margin-right: 0.5rem;
    margin-top: 1.5rem; 
	cursor: pointer;
`;
