import styled from "styled-components";
import { Link } from "gatsby";

export const FooterContainer = styled.footer`
	background-color: #ffffff;
	max-width: 1024px;
	position: sticky;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-self: center;
	padding: 1rem;
	margin: auto;
`;

export const FooterWrap = styled.div`
	padding: 48px 24px 18px;
	width: 100%;
	max-width: 1040px;
	box-sizing: border-box;
	margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
	display: flex;
	/* justify-content: center; */
	@media screen and (max-width: 820px) {
		padding-top: 32px;
	}
`;

export const FooterLinksWrapper = styled.div`
	display: flex;
	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;
export const FooterLinkItems = styled.div`
	margin: 16px 16px 0 0;
	text-align: left;
	width: 160px;
	box-sizing: border-box;
	color: #000000;
	font-weight: bold;
	text-align: right;
	@media screen and (max-width: 820px) {
		text-align: center;
	}
`;

export const FooterLinkTitle = styled.p`
	text-align: center;
	margin: 0;
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 8px;
	a {
		text-decoration: none;
		color: #000000;
		&:hover {
			color: #3a36ff;
			transition: 0.3 ease-out;
		}
	}
`;

export const FooterLink = styled(Link)`
	display: block;
	color: #000000;
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 12px;
	&:hover {
		color: #3a36ff;
		transition: 0.3 ease-out;
	}
`;

export const SocialMediaWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 32px;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;
export const SocialLogo = styled.div`
	justify-self: start;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 48px;
	img {
		margin-right: 16px;
		height: 32px;
	}
	@media screen and (max-width: 820px) {
		padding-bottom: 8px;
		img {
			margin-right: 16px;
			height: 32px;
		}
`;
