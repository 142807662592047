import React, { useState, useEffect } from "react";
import {
	Nav,
	NavbarContainer,
	NavIcon,
	NavItem,
	NavLinks,
	NavLogo,
} from "./NavbarElements";
import PGlogo from "../../images/logo.svg";

const Navbar = () => {
	const [showTopBtn, setShowTopBtn] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 400) {
				setShowTopBtn(true);
			} else {
				setShowTopBtn(false);
			}
		});
	}, []);
	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	return (
		<>
			<Nav>
				<NavbarContainer>
					<NavLogo href="/">
						<NavIcon src={PGlogo} alt="Navbar-Logo" />
					</NavLogo>
					<NavItem>
						{showTopBtn && (
							<NavLinks onClick={goToTop}>Doe mee</NavLinks>
						)}
					</NavItem>
				</NavbarContainer>
			</Nav>
		</>
	);
};
export default Navbar;
