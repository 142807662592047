import React from "react";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
`;

const FeedbackTitle = styled.h1`
  margin: 8px;
`;

const FeedbackText = styled.p`
  margin: 8px;
`;

const FeedbackButton = styled.a`
  width: 200px;
  padding: 8px;
  margin: 8px;
  background-color: #3a36ff;
  color:  #fff;
  border-radius: 16px;
  font-size: 16px;
  text-decoration: none;
`;
const VerifyEmailSuccess = () => {
  return (
    <>
      <Navbar />
      <FeedbackContainer>
        <FeedbackTitle>Verifieer e-mail</FeedbackTitle>
        <FeedbackText>Klik op de knop om je e-mail adres te bevestigen</FeedbackText>
        <FeedbackButton href={`posgez://verify-email-success`}>Open App</FeedbackButton>
      </FeedbackContainer>
      <Footer />
    </>
  );
};

export default VerifyEmailSuccess